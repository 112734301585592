import React from "react";

import "../style/proyectos.css";
import jrImagen from "../img/jr.jpg";
import hundirlaflota from "../img/hundirlaflota.jpg";
import hundirlaflota2 from "../img/hundirlaflota2.jpg";
import trello from "../img/Trello.jpg";
import sudoku from "../img/sudoku.jpg";
import jrsneakers1 from "../img/jrsneakers1.jpg";
import jrsneakers2 from "../img/jrsneakers2.png";
import jrsneakers3 from "../img/jrsneakers3.png";
import jrsneakers4 from "../img/jrsneakers4.png";
import jrsneakers5 from "../img/jrsneakers5.jpg";
import jrsneakers6 from "../img/jrsneakers6.jpg";
import jrsneakers7 from "../img/jrsneakers7.png";
import "../style/modals/modalJRSneakers.css";
import "../style/modals/modalTrello.css";
import "../style/modals/modalHundirlaflota.css";
import "../style/modals/modalSudoku.css";
import video1 from "../videos/JR-Sneakers.mp4";

const Proyectos = () => {
  function modalJRSneakers() {
    const close_JRsneakers = document.querySelectorAll(".close_JRsneakers")[0];
    const btn_JRsneakers = document.getElementById("btn_filtro");
    const modalJRsneakers = document.querySelectorAll(".modalJRsneakers")[0];

    btn_JRsneakers.addEventListener("click", () => {
      modalJRsneakers.style.opacity = "1";
      modalJRsneakers.style.visibility = "visible";
      modalJRsneakers.classList.toggle("modal_close");
    });

    close_JRsneakers.addEventListener("click", () => {
      modalJRsneakers.classList.toggle("modal_close");
      modalJRsneakers.style.opacity = "0";
      modalJRsneakers.style.visibility = "hidden";
    });
    // modalJRsneakers.addEventListener("click", () => {
    //   modalJRsneakers.classList.toggle("modal_close");
    //   modalJRsneakers.style.opacity = "0";
    //   modalJRsneakers.style.visibility = "hidden";
    // });
  }
  function modalTrello() {
    const close_Trello = document.querySelectorAll(".close_Trello")[0];
    const btn_Trello = document.getElementById("btn_Trello");
    const modalTrello = document.querySelectorAll(".modalTrello")[0];

    btn_Trello.addEventListener("click", () => {
      modalTrello.style.opacity = "1";
      modalTrello.style.visibility = "visible";
      modalTrello.classList.toggle("modal_close");
    });
    close_Trello.addEventListener("click", () => {
      modalTrello.classList.toggle("modal_close");
      modalTrello.style.opacity = "0";
      modalTrello.style.visibility = "hidden";
    });
    // modalTrello.addEventListener("click", () => {
    //   modalTrello.classList.toggle("modal_close");
    //   modalTrello.style.opacity = "0";
    //   modalTrello.style.visibility = "hidden";
    // });
  }
  function modalHundirlaflota() {
    const close_Hundirlaflota = document.querySelectorAll(
      ".close_Hundirlaflota"
    )[0];
    const btn_Hundirlaflota = document.getElementById("btn_Hundirlaflota");

    const modalHundirlaflota = document.querySelectorAll(
      ".modalHundirlaflota"
    )[0];

    btn_Hundirlaflota.addEventListener("click", () => {
      modalHundirlaflota.style.opacity = "1";
      modalHundirlaflota.style.visibility = "visible";
      modalHundirlaflota.classList.toggle("modal_close");
    });
    close_Hundirlaflota.addEventListener("click", () => {
      modalHundirlaflota.classList.toggle("modal_close");
      modalHundirlaflota.style.opacity = "0";
      modalHundirlaflota.style.visibility = "hidden";
    });
    // modalHundirlaflota.addEventListener("click", () => {
    //   modalHundirlaflota.classList.toggle("modal_close");
    //   modalHundirlaflota.style.opacity = "0";
    //   modalHundirlaflota.style.visibility = "hidden";
    // });
  }
  function modalSudoku() {
    const close_Sudoku = document.querySelectorAll(".close_Sudoku")[0];
    const btn_Sudoku = document.getElementById("btn_Sudoku");
    const modalSudoku = document.querySelectorAll(".modalSudoku")[0];

    btn_Sudoku.addEventListener("click", () => {
      modalSudoku.style.opacity = "1";
      modalSudoku.style.visibility = "visible";
      modalSudoku.classList.toggle("modal_close");
    });
    close_Sudoku.addEventListener("click", () => {
      modalSudoku.classList.toggle("modal_close");
      modalSudoku.style.opacity = "0";
      modalSudoku.style.visibility = "hidden";
    });
    // modalSudoku.addEventListener("click", () => {
    //   modalSudoku.classList.toggle("modal_close");
    //   modalSudoku.style.opacity = "0";
    //   modalSudoku.style.visibility = "hidden";
    // });
  }
  function irJRSneakers() {
    window.location.replace("../../../jr-sneakers/index.php");
  }
  function irTrello() {
    window.location.replace("../../../trello/trello.html");
  }
  function irHundirlaflota() {
    window.location.replace("../../../hundirlaflota/hf.html");
  }
  function irsudoku() {
    window.location.replace("../../../sudoku/index.html");
  }
  return (
    <div>
      {/* // Empieza el modalJRSneakers */}
      <div class="row">
        <div class="col modalJRsneakers">
          <div class="row">
            <div class="col elmodalJRsneakers">
              <div class="row tituloJRsneakers">
                <a
                  href="../../../jr-sneakers/index.php"
                  target="_blank"
                  className="linkSudoku"
                >
                  Probar
                </a>
                <div class="col">JR-Sneakers</div>
                <i class="bi bi-x-circle close_JRsneakers"></i>
              </div>
              <div class="row">
                <div class="col informacionJRsneakers">
                  <p>
                    Este es mi Proyecto final de grado el cual está realizado
                    con JavaScript para la parte del frontend junto con
                    Bootstrap para maquetar y hacerlo responsive, PhP para la
                    parte de Backend usando MySQL para la manipulación de base
                    de datos y para las imágenes PhotoShop.
                  </p>
                  <p>
                    La aplicación web consiste en una tienda online de
                    zapatillas en la cual puedes registrarse, visualizar,
                    comentar y comprar zapatillas.
                  </p>
                </div>
              </div>
              <div class="row pt-5">
                <div class="col-lg-8 col-sm-12 p-4">
                  <img class="imgJRsneakers1" src={jrsneakers1} />
                </div>
                <div class="col-lg-4 col-sm-12">
                  <p class="tituloimgJRsneakers1">
                    - Vista inicial JR-Sneakers
                  </p>
                  <p class="textoimgJRsneakers">
                    Esto es lo primero que visualizaremos al entrar en la pagina
                    web.
                  </p>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-4 p-4">
                  <img class="imgJRsneakers3" src={jrsneakers3} />
                </div>
                <div class="col-4 p-4">
                  <img class="imgJRsneakers2" src={jrsneakers2} />
                </div>
                <div class="col-4 p-4">
                  <img class="imgJRsneakers4" src={jrsneakers4} />
                </div>
              </div>

              <div class="row mt-5 pt-5">
                <div class="col-lg-4 col-sm-12 order-sm-2">
                  <p class="tituloimgJRsneakers1">
                    - Vista una vez inicias sesión
                  </p>
                  <p class="textoimgJRsneakers">
                    Una vez te registres e inicies sesión la aplicación web se
                    vera de esta manera.
                  </p>
                  <p class="textoimgJRsneakers">
                    Como podemos visualizar se nos ha desbloqueado dos botones
                    uno el de Perfil y otro el carrito de compra.
                  </p>
                </div>
                <div class="col-lg-8 col-sm-12 order-sm-1 p-4">
                  <img class="imgJRsneakers1" src={jrsneakers5} />
                </div>
              </div>

              <div class="row mt-5 pt-5">
                <div class="col-lg-8 col-sm-12 order-sm-1 p-4">
                  <img class="imgJRsneakers1" src={jrsneakers6} />
                </div>
                <div class="col-lg-4 col-sm-12 order-sm-2">
                  <p class="tituloimgJRsneakers1">
                    - Vista configuración zapatillas{" "}
                  </p>
                  <p class="textoimgJRsneakers">
                    Al darle a configurar cualquier zapatilla nos mostrara esto
                    donde podremos visualizar los diferentes colores de la
                    zapatilla, configurar talla y seleccionar el color elegido
                    para añadirlo al carrito.
                  </p>
                  <p class="textoimgJRsneakers">
                    También contamos con un apartado donde podemos escribir un
                    comentario o visualizar los comentarios de los diferentes
                    usuarios.
                  </p>
                </div>
              </div>

              <div class="row mt-5 pt-5">
                <div class=" col-lg-4  col-sm-12 order-sm-2">
                  <p class="tituloimgJRsneakers1">- Vista carrito de compra </p>
                  <p class="textoimgJRsneakers">
                    Una vez hayamos configurado las zapatillas y las hayamos
                    enviado al carrito podremos pinchar en el botón con el icono
                    carrito y nos saldrá este desplegable con todas las
                    zapatillas seleccionadas y el precio total, también
                    tendremos un icono de papelera por si alguna no nos gusta y
                    queremos eliminarla.
                  </p>
                  <p class="textoimgJRsneakers">
                    Para terminar de comprar seleccionaremos el botón de
                    comprar.
                  </p>
                </div>
                <div class=" col-lg-8 col-sm-12 order-sm-1 p-4">
                  <img class="imgJRsneakers1" src={jrsneakers7} />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  {" "}
                  <video class="videoJRsneakers" src={video1} controls></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // Acaba el modalJRSneakers */}
      {/* Empieza modal de trello */}
      <div class="row">
        <div class="col modalTrello">
          <div class="row">
            <div class="col elmodalTrello">
              <div class="row tituloTrello">
                <a
                  href="../../../trello/trello.html"
                  target="_blank"
                  className="linkSudoku"
                >
                  Probar
                </a>
                <div class="col">Trello</div>
                <i class="bi bi-x-circle close_Trello"></i>
              </div>
              <div class="row">
                <div class="col informacionTrello">
                  Es un trabajo que consiste en hacer una lista, que se guarde
                  en el tiempo con "localStoraje" de JavaScript asemejándose a
                  la AppWeb Trello la cual te permite coger notas
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <img class="imgTrello1" src={trello} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Acaba modal de trello  */}
      {/* Empieza modal de hundir la flota */}
      <div class="row">
        <div class="col modalHundirlaflota">
          <div class="row">
            <div class="col elmodalHundirlaflota">
              <div class="row tituloHundirlaflota">
                <a
                  href="../../../hundirlaflota/hf.html"
                  target="_blank"
                  className="linkSudoku"
                >
                  Probar
                </a>
                <div class="col">Hundir la flota</div>
                <i class="bi bi-x-circle close_Hundirlaflota"></i>
              </div>
              <div class="row">
                <div class="col informacionHundirlaflota">
                  Es el famoso juego de hundir la flota, está hecho con
                  JavaScript tiene varias dificultades ademas de un login que
                  busca los usuarios, contraseñas en un .json.
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <img class="imgHundirlaflota1" src={hundirlaflota2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Acaba modal de hundir la flota  */}
      {/* Empieza modal de sudoku  */}
      <div class="row">
        <div class="col modalSudoku">
          <div class="row">
            <div class="col elmodalSudoku">
              <div class="row tituloSudoku">
                <a
                  href="../../../sudoku/index.html"
                  target="_blank"
                  className="linkSudoku"
                >
                  Probar
                </a>
                <div class="col">Sudoku</div>
                <i class="bi bi-x-circle close_Sudoku"></i>
              </div>
              <div class="row">
                <div class="col informacionSudoku">
                  Es el mítico juego de inteligencia Sudoku, está hecho con
                  JavaScript.
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <img class="imgSudoku1" src={sudoku} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Acaba modal sudoku */}
      <div className="row mt-5  div_proyectos">
        <div className="row mt-5 mb-5">
          <div className="col d-flex justify-content-center">
            <h2>Proyectos</h2>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
            <div className="card">
              <div>
                <img className="img_proyectos" alt="" src={jrImagen}></img>
                <div className="div_btnproyectos">
                  <button
                    className="btn_proyectos"
                    id="btn_filtro"
                    onClick={modalJRSneakers}
                  >
                    Visualizar
                  </button>
                  <button
                    className="btn_proyectos"
                    id="btn_filtro"
                    onClick={irJRSneakers}
                  >
                    Probar
                  </button>
                </div>
              </div>
              <p className="nombreProyectos">JR-Sneakers</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
            <div className="card">
              <div>
                <img className="img_proyectos" alt="" src={trello}></img>
                <div className="div_btnproyectos">
                  <button
                    className="btn_proyectos"
                    id="btn_Trello"
                    onClick={modalTrello}
                  >
                    Visualizar
                  </button>
                  <button
                    className="btn_proyectos"
                    id="btn_filtro"
                    onClick={irTrello}
                  >
                    Probar
                  </button>
                </div>
              </div>
              <p className="nombreProyectos">Trello</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
            <div className="card">
              <div>
                <img className="img_proyectos" alt="" src={hundirlaflota}></img>
                <div className="div_btnproyectos">
                  <button
                    className="btn_proyectos"
                    id="btn_Hundirlaflota"
                    onClick={modalHundirlaflota}
                  >
                    Visualizar
                  </button>
                  <button
                    className="btn_proyectos"
                    id="btn_filtro"
                    onClick={irHundirlaflota}
                  >
                    Probar
                  </button>
                </div>
              </div>
              <p className="nombreProyectos">Hundir la flota</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center">
            <div className="card">
              <div>
                <img className="img_proyectos" alt="" src={sudoku}></img>
                <div className="div_btnproyectos">
                  <button
                    className="btn_proyectos"
                    id="btn_Sudoku"
                    onClick={modalSudoku}
                  >
                    Visualizar
                  </button>
                  <button
                    className="btn_proyectos"
                    id="btn_filtro"
                    onClick={irsudoku}
                  >
                    Probar
                  </button>
                </div>
              </div>
              <p className="nombreProyectos">Sudoku</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proyectos;
