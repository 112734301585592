import React from "react";
import "../style/home.css";
import diseñoWeb from "../img/diseno-web1.png";

const Inicio = () => {
  return (
    <div className="row div_home p-3">
      <div className="col-lg-6 col-md-12  order-md-2 order-lg-1 col-12 div_imagen">
        <img className="img_inicio" src={diseñoWeb}></img>
      </div>
      <div className="col-lg-6 col-md-12  order-md-1 order-lg-2 col-12 div_inicio">
        <h2 className="titulo_inicio">Desarrollador web</h2>
        <p className="texto_inicio">
          Diseño y Desarrollo de aplicaciones web personalizadas.
        </p>
      </div>
    </div>
  );
};

export default Inicio;
