import React from "react";
import "../style/sobremi.css";
import yo from "../img/JorgeRomero.png";
import jorgecv from "../img/jorgeCV.pdf";

const Sobremi = () => {
  return (
    <div className="row div_sobremi">
      <div className="col-lg-6 col-md-12 col-12 div_sobremi_img">
        <img className="img_sobremi" src={yo}></img>
      </div>
      <div className="col-lg-6 col-md-12 col-12 div_sobremi_texto">
        <h2 className="titulo_sobremi">Jorge Romero Martínez</h2>
        <p className="texto_sobremi">
          Soy un desarrollador de aplicaciones web con ganas de seguir
          adquiriendo conocimientos y formandome en el mundo de la programacion
          web.
        </p>
        <p className="texto_sobremi">
          Mis puntos fuertes son la creatividad y el diseño de la interfaz entre
          otros.
        </p>
        <div className="row mt-5">
          <div className="col">
            <a className="btn_cv mt-5" href={jorgecv} download="jorgeCV.pdf">
              Descargar CV
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sobremi;
