import "./style/body.css";
import { Routes, Route } from "react-router-dom";
import Menu from "./Components/menu";
import Conocimientos from "./Components/conocimientos";
import Sobremi from "./Components/sobremi";
import Proyectos from "./Components/proyectos";
import Inicio from "./Components/inicio";
import Footer from "./Components/footer";

function App() {
  return (
    <div className="container-fluid">
      <Menu />
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/conocimientos" element={<Conocimientos />} />
        <Route path="/sobremi" element={<Sobremi />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
