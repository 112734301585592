import React from "react";
import { Link } from "react-router-dom";
import "../style/header.css";

const Menu = () => {
  return (
    <div className="row pt-3">
      <div className="col-lg-5 col-md-12 d-flex justify-content-center ">
        <Link to="/" className="btn_nombre">
          Jorge Romero
        </Link>
      </div>
      <div className="col-lg-2 col-md-4">
        <div className="dentro_nav">
          <Link to="/sobremi" className="btn_nav">
            Sobre mi
          </Link>
        </div>
      </div>
      <div className="col-lg-2 col-md-4">
        <div className="dentro_nav">
          <Link to="/conocimientos" className="btn_nav">
            Conocimientos
          </Link>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 ">
        <div className="dentro_nav">
          <Link to="/proyectos" className="btn_nav">
            Proyectos
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Menu;
