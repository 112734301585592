import React from "react";
import "../style/contactos.css";
import "../style/modals/modalCorreo.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  // function modalCorreo() {
  //   const close_filtro = document.querySelectorAll(".close_filtro")[0];
  //   const btnCorreo = document.getElementById("btn_filtro");
  //   const modalCorreo = document.querySelectorAll(".modalCorreo")[0];

  //   btnCorreo.addEventListener("click", () => {
  //     modalCorreo.style.opacity = "1";
  //     modalCorreo.style.visibility = "visible";
  //     modalCorreo.classList.toggle("modal_close");
  //   });
  //   close_filtro.addEventListener("click", () => {
  //     modalCorreo.classList.toggle("modal_close");
  //     modalCorreo.style.opacity = "0";
  //     modalCorreo.style.visibility = "hidden";
  //   });
  // }
  return (
    <div className="row">
      {/* Start modal  */}
      {/*NECESITA REVISION NO VA  */}
      <div className="col modalCorreo">
        <div className="row">
          <div className="col elmodalCorreo">
            <div className="row">
              <div className="col modal_close_filtro">
                <i className="bi bi-x-circle close_filtro"></i>
              </div>
            </div>
            <div className="row">
              <div className="col tituloCorreo">Correo</div>
            </div>
            <div className="row">
              <div className="col correo">jorge@jorgeromeromartinez.es</div>
            </div>
          </div>
        </div>
      </div>

      {/* End modal  */}

      <div className="col d-flex justify-content-center footer">
        <div className="row">
          {/* <div className="col p-3"> */}
          {/* <i class="bi  bi-envelope svg_contactos"></i> */}
          {/* 
            <button id="btnCorreo" onClick={modalCorreo}>
              <i class="bi  bi-envelope svg_contactos"></i>
            </button> */}
          {/* </div> */}
          <div className="col-6 p-3">
            <a href="https://www.linkedin.com/in/jorge-romero-mart%C3%ADnez-04b194198/">
              <i class="bi bi-linkedin svg_contactos"></i>
            </a>
          </div>
          <div className="col-6 p-3">
            <a href="https://github.com/JorgeRomeroMartinez?tab=repositories">
              <i class="bi bi-github svg_contactos"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
