import React from "react";
import "../style/conocimientos.css";
import html from "../img/html.png";
import css from "../img/css.png";
import js from "../img/js.png";
import bootstrap from "../img/bootstrap.png";
import react from "../img/react.png";
import php from "../img/php.png";
import mysql from "../img/mysql.png";
import photoshop from "../img/adobe-photoshop.png";
import figma from "../img/figma.png";

const Conocimientos = () => {
  return (
    <div className="row p-3 pt-5 div_conocimientos">
      <div className="col">
        <div className="row mt-5 mb-5">
          <div className="col d-flex justify-content-center">
            <h2>Conocimientos</h2>
          </div>
        </div>
        <div className="row conocimientos">
          {/* Frontend */}
          <div className="col-lg-4 col-sm-6 col-4 p-5">
            <h4 className="tiposConocimientos">Frontend</h4>
            <div className="row frontend">
              <div className="col-lg-4 col-md-6 col-4 mt-5">
                <p className="todosConocimientos">HTML</p>{" "}
                <img className="lenguajes" src={html}></img>
              </div>
              <div className="col-lg-4 col-md-6 col-4 mt-5">
                <p className="todosConocimientos">CSS</p>{" "}
                <img className="lenguajes" src={css}></img>
              </div>
              <div className="col-lg-4 col-md-6 col-4 mt-5">
                <p className="todosConocimientos">JavaScript</p>{" "}
                <img className="lenguajes" src={js}></img>
              </div>
              <div className="col-lg-4 col-md-6 col-4 mt-5">
                <p className="todosConocimientos">Bootstrap</p>
                <img className="lenguajes" src={bootstrap}></img>
              </div>
              <div className="col-lg-4 col-md-6 col-4 mt-5">
                <p className="todosConocimientos">React</p>{" "}
                <img className="lenguajes" src={react}></img>
              </div>
            </div>
          </div>
          {/* Backend */}
          <div className="col-lg-4 col-sm-6 col-4  p-5">
            <h4 className="tiposConocimientos">Backend</h4>
            <div className="row frontend">
              <div className="col-lg-4 col-md-6 col-4  mt-5">
                <p className="todosConocimientos">PHP</p>{" "}
                <img className="lenguajes" src={php}></img>
              </div>
              <div className="col-lg-4 col-md-6 col-4 mt-5">
                <p className="todosConocimientos">MySQL</p>{" "}
                <img className="lenguajes" src={mysql}></img>
              </div>
            </div>
          </div>
          {/* Diseño   */}
          <div className="col-lg-4 col-sm-12  p-5">
            <h4 className="tiposConocimientos">Diseño</h4>
            <div className="row frontend">
              <div className="col-lg-4 col-md-6 col-4  mt-5">
                <p className="todosConocimientos">Photoshop</p>
                <img className="lenguajes" src={photoshop}></img>
              </div>
              <div className="col-lg-4 col-md-6 col-4  mt-5">
                <p className="todosConocimientos">Figma</p>{" "}
                <img className="lenguajes" src={figma}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="separador"></div>
    </div>
  );
};

export default Conocimientos;
